@import "~bootstrap/scss/bootstrap";

body{
  font-family:Georgia, 'Times New Roman', Times, serif;
  text-align: center;
}

#form{
  width: 60%;
  margin: auto;
}